import { Avatar, WrapItem, Text } from "@chakra-ui/react";
import { LandingLocale } from "../../localization/landing";

export default function AvatarList({ l }: { l: LandingLocale }) {
  const AvatarItem = (props: { image: string; name: string; country: string }) => (
    <WrapItem flexDir={"column"} alignItems="center" mx={2}>
      <Avatar
        size="2xl"
        name={props.name}
        src={`/avatars/microsoft-server-speech-text-to-speech-voice--${props.image}`}
      />
      <Text fontSize="lg" mt={1} color="white">
        {props.name}
      </Text>
      <Text fontSize="xs" fontWeight={"bold"} color="brand.gray.100">
        {props.country}
      </Text>
    </WrapItem>
  );

  return (
    <>
      <AvatarItem
        image="es-ve--sebastianneural-.jpeg"
        name="Sebastian"
        country={l["country.venezuela"]}
      />
      <AvatarItem image="ja-jp--mayuneural-.jpeg" name="Mayu" country={l["country.japan"]} />
      <AvatarItem image="ar-dz--ismaelneural-.jpeg" name="Ismael" country={l["country.algeria"]} />
      <AvatarItem image="fr-fr--deniseneural-.jpeg" name="Denise" country={l["country.france"]} />
      <AvatarItem image="es-es--teoneural-.jpeg" name="Teo" country={l["country.spain"]} />
      <AvatarItem image="zh-cn--xiaoqiuneural-.jpeg" name="Xiaoqiu" country={l["country.china"]} />
      <AvatarItem image="en-us--davisneural-.jpeg" name="Davis" country={l["country.usa"]} />
      <AvatarItem image="en-gb--abbineural-.jpeg" name="Abbi" country={l["country.england"]} />
      <AvatarItem image="pt-br--valerioneural-.jpeg" name="Valerio" country={l["country.brazil"]} />
      <AvatarItem image="it-it--imeldaneural-.jpeg" name="Imelda" country={l["country.italy"]} />
      <AvatarItem image="da-dk--jeppeneural-.jpeg" name="Jeppe" country={l["country.denmark"]} />
    </>
  );
}
