import { Box, Container, Heading, Icon, SimpleGrid, Square, Stack, Text } from "@chakra-ui/react";
import { AiFillAudio } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { HiLanguage } from "react-icons/hi2";
import { MdLanguage } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import { FaChalkboardTeacher, FaFireAlt } from "react-icons/fa";
import { RiTranslate } from "react-icons/ri";
import { FiBook } from "react-icons/fi";
import { LandingLocale } from "../localization/landing";

interface Props {
  l: LandingLocale;
}

export function Features({ l }: Props) {
  const features = [
    {
      name: l["landing.text.voiceConversations"],
      description: l["landing.text.voiceConversationsDescription"],
      icon: TiMessages,
    },
    {
      name: l["landing.text.pronunciation"],
      description: l["landing.text.pronunciationDescription"],
      icon: AiFillAudio,
    },
    {
      name: l["landing.text.feedback"],
      description: l["landing.text.feedbackDescription"],
      icon: FaChalkboardTeacher,
    },
    {
      name: l["landing.text.multipleLanguages"],
      description: l["landing.text.multipleLanguagesDescription"],
      icon: MdLanguage,
    },
    {
      name: l["landing.text.progress"],
      description: l["landing.text.progressDescription"],
      icon: BsStars,
    },
    {
      name: l["landing.text.wordbook"],
      description: l["landing.text.wordbookDescription"],
      icon: FiBook,
    },
    {
      name: l["landing.text.streaks"],
      description: l["landing.text.streaksDescription"],
      icon: FaFireAlt,
    },
    {
      name: l["landing.text.crosstalk"],
      description: l["landing.text.crosstalkDescription"],
      icon: RiTranslate,
    },
    {
      name: l["landing.text.translations"],
      description: l["landing.text.translationsDescription"],
      icon: HiLanguage,
    },
  ];

  return (
    <Box as="section" bg="bg.surface">
      <Container py={{ base: "16", md: "24" }}>
        <Stack spacing={{ base: "12", md: "16" }}>
          <Stack spacing={{ base: "4", md: "5" }} align="center" textAlign="center">
            <Stack spacing="3">
              <Heading size={{ base: "sm", md: "md" }}>
                {l["landing.text.featureSpotlight"]}
              </Heading>
            </Stack>
            <Text color="fg.muted" fontSize={{ base: "lg", md: "xl" }} maxW="3xl">
              {l["landing.text.featureSpotlightDescription"]}
            </Text>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            columnGap={8}
            rowGap={{ base: 10, md: 16 }}
          >
            {features.map((feature, i) => (
              <Stack key={i} spacing={{ base: "4", md: "5" }} align="center" textAlign="center">
                <Square
                  size={{ base: "10", md: "12" }}
                  bg="brand.primary.main"
                  color="fg.inverted"
                  borderRadius="lg"
                >
                  <Icon as={feature.icon} boxSize={{ base: "5", md: "6" }} />
                </Square>
                <Stack spacing={{ base: "1", md: "2" }}>
                  <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="medium">
                    {feature.name}
                  </Text>
                  <Text color="fg.muted">{feature.description}</Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
}
