import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/use-local-storage";

export default function PolandModal() {
  const [isOpen, setIsOpen] = useState(false);
  const modalSeenStorage = useLocalStorage<string>("seen-country-website-modal", "false");

  useEffect(() => {
    const isPolish = navigator.language.startsWith("pl");
    // const isPolish = navigator.language.startsWith("en"); // for testing

    if (
      !modalSeenStorage.isLoadingStorage &&
      modalSeenStorage.storageData === "false" &&
      isPolish
    ) {
      setIsOpen(true);
      modalSeenStorage.setStorageData("true");
    }
  }, [modalSeenStorage]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent px={5} py={5}>
        <ModalHeader textAlign={"center"}>Witaj w Talkio!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Wygląda na to, że używasz polskiej wersji przeglądarki. Czy chcesz przejść do polskiej
          wersji Talkio? Oferujemy pełne wsparcie językowe, aby ułatwić Ci naukę i doskonalenie
          umiejętności językowych.
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Anuluj
          </Button>
          <Button colorScheme="brand.green" mr={3} as="a" href="https://talkio.pl">
            Przejdź do polskiej wersji
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
