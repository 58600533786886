import { HStack, Icon, List, ListItem, Text } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { LandingLocale } from "../localization/landing";

interface Props {
  color?: string;
  checkMarkColor?: string;
  my?: number;
  fontSize?: string;
  features?: string[];
  spacing?: number;
  listItemSpacing?: number;
  l: LandingLocale;
}

export default function FeatureCheckList(props: Props) {
  const features = props.features || [
    props.l["feature.lifeLikeConversations"],
    props.l["feature.pronunciationTool"],
    props.l["feature.detailedFeedback"],
    props.l["feature.interactiveWordbook"],
    props.l["feature.supportsLanguages"],
  ];

  return (
    <List my={props.my || 8} id="feature-checklist" color={props.color}>
      {features.map((feature, i) => (
        <ListItem key={i} mb={props.listItemSpacing}>
          <HStack spacing={props.spacing || 4}>
            <Icon as={FiCheck} color={props.checkMarkColor || "brand.green.400"} boxSize="6" />
            <Text fontSize={props.fontSize || "sm"} textAlign="left">
              {feature}
            </Text>
          </HStack>
        </ListItem>
      ))}
    </List>
  );
}
