import { Flex, Spinner } from "@chakra-ui/react";

interface Props {
  minHeight?: string;
}

export default function SpinnerFull(props: Props) {
  const minHeight = props.minHeight || "300px";

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
      minHeight={minHeight}
    >
      <Spinner></Spinner>
    </Flex>
  );
}
