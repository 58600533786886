import {
  Card,
  CardBody,
  StackDivider,
  Box,
  Flex,
  Icon,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import Link from "next/link";
import { ImQuotesLeft } from "react-icons/im";
import { LandingLocale } from "../../localization/landing";

export default function TestimonialList({ l }: { l: LandingLocale }) {
  const TestimonialItem = (props: {
    text: string;
    author: string;
    link: string;
    borderColor: string;
  }) => (
    <Card mb="5" borderTop={`5px solid ${props.borderColor}`}>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Flex>
              <Icon color={props.borderColor} as={ImQuotesLeft} fontSize="2xl" mr="5" />
              <Heading fontSize="md" fontWeight={"normal"} lineHeight={1.6} color="brand.gray.600">
                {props.text}
              </Heading>
            </Flex>
            <Text pt="2" fontSize="xs" color="brand.gray.600" textAlign="right">
              <Link href={props.link} target="_blank" rel="nofollow noopener noreferrer">
                {props.author}
              </Link>
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );

  return (
    <>
      <TestimonialItem
        text={l["testimonial.text1"]}
        author="Read more on terrybrock.com"
        link="https://terrybrock.com/revolutionize-your-language-learning-with-ai-technology-talkio-ai-to-the-rescue/"
        borderColor="var(--chakra-colors-brand-secondary-200)"
      />
      <TestimonialItem
        text={l["testimonial.text2"]}
        author="One Minute English"
        link="https://oneminuteenglish.org/en/talkio-a-i-review"
        borderColor="var(--chakra-colors-brand-secondary-300)"
      />
      <TestimonialItem
        text={l["testimonial.text3"]}
        author="Read more on x.com"
        link="https://twitter.com/markdeloura/status/1680945665923317765"
        borderColor="var(--chakra-colors-brand-secondary-400)"
      />
      <TestimonialItem
        text={l["testimonial.text4"]}
        author="Master Mandarin on YouTube"
        link="https://www.youtube.com/watch?v=4CR3xWN6xik&ab_channel=MasterMandarin"
        borderColor="var(--chakra-colors-brand-secondary-500)"
      />
    </>
  );
}
